<template>
  <svg
    fill="none"
    height="18"
    viewBox="0 0 20 18"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 17H3C1.89543 17 1 16.1046 1 15L1 3C1 1.89543 1.89543 1 3 1L13 1C14.1046 1 15 1.89543 15 3V4M17 17C15.8954 17 15 16.1046 15 15L15 4M17 17C18.1046 17 19 16.1046 19 15V6C19 4.89543 18.1046 4 17 4L15 4M11 1L7 1M5 13H11M5 5H11V9H5V5Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>
